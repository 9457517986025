import { AuthSourceOptions } from "./AuthSourceOptions.type";

/**
 * List of (brand) names that are not subject to change
 * These are linked to AuthSourceOptions.type.ts in the backend.
 */
export const authSourceNames: Partial<Record<AuthSourceOptions, string>> = {
    // cspell:disable -- Disable spell checking on localised country names.
    google: "Google",
    microsoft: "Microsoft",
    apple: "Apple",
    surfconext: "SURFconext",
    "klas-cement": "KlasCement",
    vidis: "VIDIS",

    // TODO: Determine whether to use the brand name or a custom translation for these ids?
    // "leer-id": "LeerID",
    // "vlaamse-overheid": "Vlaamse overheid",
    // cspell:enable -- Restore spell checking.
};
