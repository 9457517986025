if (!String.prototype.replaceAll) {
    /**
     * replaceAll polyfill
     *
     * @param find The string or regular expression to match.
     * @param replace The replacement string.
     * @returns The resulting string.
     */
    // @ts-expect-error -- Polyfill
    // eslint-disable-next-line no-extend-native -- Polyfill for Browsers < Oct 2020
    String.prototype.replaceAll = function (find: RegExp | string, replace: string): string {
        // If a regex pattern
        if (find instanceof RegExp) {
            return this.replace(find, replace);
        }

        // If a string
        return this.replace(new RegExp(find.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"), replace);
    };
}
