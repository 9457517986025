import { alpha } from "@suid/material";
import styled from "@suid/system/styled";
import { type AppFrameProps } from ".";

// Note that the unit test can't handle "(1035px <= width <= 1050px)" syntax.
export const chromeBookQuery = "(min-width: 1035px) and (max-width: 1050px) and (min-height: 480px) and (max-height: 495px)";

export const AppFrameStyled = styled("div")<AppFrameProps>(
    {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    (params) => {
        const props = params.props as AppFrameProps;
        const { theme } = params;
        const { reserveAppBarHeight, fullWidth, minContentWidth } = props;

        return {
            paddingTop: reserveAppBarHeight ? theme.spacing(8) : 0,
            display: "flex",
            zIndex: 0,
            "--frameColumns": "1",
            minWidth: "min(420px, 100%)",

            "& > .frame": {
                margin: "auto",
                maxWidth: minContentWidth ? "100%" : "1200px",
                width: fullWidth
                    ? "auto"
                    : minContentWidth
                        ? "min-content"
                        : "420px",
                minWidth: "min(420px, 100%)",
                "> .MuiPaper-root": {
                    minHeight: "820px",
                    marginTop: theme.spacing(2),
                },
                "> footer": {
                    textAlign: "right",
                    paddingTop: theme.spacing(4),
                    marginTop: theme.spacing(-3),
                    position: "relative",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingBottom: theme.spacing(1),
                    color: "rgba(255, 255, 255, 0.5)",
                    ".MarkdownLink": {
                        color: theme.palette.common.white,
                    },
                    "&::before": {
                        // Separate lowered background so we keep interactivity on the links
                        zIndex: "-1",
                        backgroundColor: alpha(theme.palette.background.default as string, 0.7),
                        borderRadius: `0 0 ${theme.spacing(3)} ${theme.spacing(3)}`,
                        content: '""',
                        display: "block",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0px",
                    },
                },
            },

            // Allow arbitrary height when the default height (including footer that uses 2rem) does not fit.
            [`@media (max-height: calc( 820px + ${theme.spacing(2)} + 3em ))`]: {
                alignItems: "initial",
                "& > .frame > .MuiPaper-root": {
                    minHeight: "initial",
                },
            },

            // Responsive mode: (chromebook)
            [`@media (${chromeBookQuery})`]: {
                "--frameColumns": "2",
            },

            // Responsive mode: fill screen when narrow (old phone) or low (chromebook)
            // Chromebook is approximately 1050px x 490px
            [`@media (width <= 600px) or (${chromeBookQuery})`]: {
                background: theme.palette.common.white,
                "& > .frame": {
                    width: "100%",
                    height: "100%",
                    minWidth: "auto",
                    "> .MuiPaper-root": {
                        padding: theme.spacing(4, 4, 0, 4),
                        minHeight: "initial",
                        height: "100%",
                        overflow: "auto",
                        marginTop: 0,
                    },
                    "> footer": {
                        position: "absolute",
                        width: "100%",
                        bottom: 0,
                        zIndex: "0",
                        background: "none",
                        paddingTop: 0,
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.text.secondary,
                        ".MarkdownLink": {
                            color: theme.palette.text.link,
                        },
                        "&::before": {
                            backgroundColor: "white",
                            top: "20px",
                        },
                    },
                },
                "&::after, &::before": {
                    content: "none",
                },
                "& .footer": {
                    position: "sticky",
                    bottom: theme.spacing(2),
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%)",
                    paddingBottom: theme.spacing(4),
                },
            },
        };
    },
);
