import { Button, Paper, Typography, useMediaQuery, useTheme } from "@suid/material";
import { t } from "i18next";
import { Component, createSignal, lazy, Show, type ParentComponent } from "solid-js";

import { AppFrameStyled } from "./AppFrame.styled";
import { type AppFrameProps } from "./index";
import { config } from "../../config";
import { MarkdownLink } from "../../utilities/Parser/MarkdownLink";
import { isPdfJsSupported } from "../../utilities/UserAgentHelper";

const PdfModalDialog = lazy(async () => import("../PdfModalDialog/PdfModalDialog"));
const MessageModalDialog = lazy(async () => import("../MessageModalDialog/MessageModalDialog"));

const PrivacyTermsModal: Component<{ pdfResource?: string; open?: boolean; onClose?: () => void }> = (props) => {
    const theme = useTheme();
    // Try and open the PDF modal if supported.
    // Note that since PdfModalDialog contains imports that may break older browsers,
    // we have a separate modal for error notifications.
    return <Show when={isPdfJsSupported()} fallback={
        <MessageModalDialog
            titleButton={
                <Button
                    href={props.pdfResource}
                    variant="contained"
                    color="secondary"
                    sx={theme.mixins.button}
                    title={t("general.btn.download_document_pdf")}
                >
                    {t("general.btn.download_document_pdf")}
                </Button>
            }
            open={!!props.pdfResource}
            onClose={props.onClose}
        >
            {t("error.p.pdf_rendering_unsupported")}
        </MessageModalDialog>}><PdfModalDialog
            pdfResource={props.pdfResource}
            open={!!props.pdfResource}
            onClose={props.onClose}
        /></Show>;
};

export const AppFrame: ParentComponent<AppFrameProps> = (props) => {
    const [pdf, setPdf] = createSignal<string>();

    const handleClose = (): void => {
        setPdf(undefined);
    };

    const theme = useTheme();
    const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    const version = config.version.env && config.version.env !== "production"
        ? `${config.version.env} - ${config.version.number}`
        : config.version.number;

    return (<AppFrameStyled {...props}>
        <div class="frame">
            <Paper sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                ...theme.mixins.paper,
                ...(isSmDownBreakpoint() && { borderRadius: 0 }),
            }}>
                {props.children}
            </Paper>
            <footer>
                <Typography sx={theme.mixins.typography} variant="caption2" as="span">{t("general.p.version_{version}", { version })}</Typography>
                <MarkdownLink
                    variant="caption2"
                    color="inherit"
                    sx={{ ...theme.mixins.typography, pl: 1.5, pr: 2.5 }}
                    text={t("general.a.privacy_and_terms")}
                    onClick={(event) => { setPdf(event.target.href); event.preventDefault(); }}
                />
            </footer>
            {pdf() ? <PrivacyTermsModal
                pdfResource={pdf()}
                open
                onClose={handleClose}
            /> : null}
        </div>
    </AppFrameStyled>);
};
