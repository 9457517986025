import { t } from "i18next";
import { User, UserManager } from "oidc-client-ts";
import { Accessor, createEffect, createResource, createSignal, Resource, ResourceActions, Setter } from "solid-js";
import { GridItem } from "../components";
import { config } from "../config";

/** App format. */
export interface App {
    /** The id of the app. */
    id: string;
    /** The name of the app. */
    name: string;
    /** The url of the app. */
    url: string;
    /** The image url for the app. */
    image_url: string;
    /** The mime type of the image. */
    image_mime: "image/png" | "image/jpeg" | "image/svg+xml";
}

/** Format of the divider. */
export interface Divider {
    /** The id of the divider. */
    id: "divider";
    /** The label of the divider. */
    label: string;
}

enum SignInStatus {
    NOT_SIGNED_IN = "NOT_SIGNED_IN",
    SINGING_IN = "SINGING_IN",
    SIGN_IN_FINiSHED = "SIGN_IN_FINiSHED",
}

/** The type of the apps with dividers. */
export type DividedAppsList = Array<App | Divider>;

/** */
class OAuthService {
    private readonly userManager: UserManager;
    private work: Array<Promise<unknown>> = [];
    public readonly user: Resource<User | null | undefined>;
    public readonly userActions: ResourceActions<User | null | undefined>;
    public readonly signInStatus: Accessor<SignInStatus>;
    public readonly setSignInStatus: Setter<SignInStatus>;

    /** Create a OAuth service. */
    public constructor() {
        const url = new URL(window.location.href);
        // without query params
        const currentPage = `${url.origin}${url.pathname}`;

        this.userManager = new UserManager({
            authority: config.app.authority,
            client_id: config.app.clientId,
            redirect_uri: currentPage,
            silent_redirect_uri: currentPage,
            post_logout_redirect_uri: currentPage,
            response_type: "code",
            response_mode: "query",
            scope: "openid email profile apps",
            // monitorSession: true,
            loadUserInfo: true,
            stopCheckSessionOnError: true,
            automaticSilentRenew: true,
            query_status_response_type: "code",
            includeIdTokenInSilentRenew: true,
            // revokeTokensOnSignout: true,
        });

        this.tryProcessLogin();

        [this.signInStatus, this.setSignInStatus] = createSignal<SignInStatus>(SignInStatus.NOT_SIGNED_IN);

        [this.user, this.userActions] = createResource(async () => {
            await Promise.all(this.work);
            this.work = [];
            return this.userManager.getUser();
        });

        createEffect(() => {
            if (this.user.state === "ready" && this.signInStatus() === SignInStatus.NOT_SIGNED_IN) {
                void this.login();
            }
        });
    }

    /**
     * Is the authenticator still loading.
     *
     * @returns True when it is still attempting loading the user.
     */
    public isLoading(): boolean {
        return this.user.loading || this.signInStatus() !== SignInStatus.SIGN_IN_FINiSHED;
    }

    /**
     * Is the user authenticated.
     *
     * @returns True when the user is successfully authenticated.
     */
    public isAuthenticated(): boolean {
        return this.user.state === "ready" && !!this.user();
    }

    /**
     * Get the user. This only be an object when a user is logged in.
     *
     * @returns the user when a user is logged in.
     */
    public getUser(): User | null {
        return this.user() ?? ((config.debug.mockResponse) ? { profile: { name: "Mock User", email: "mock.user@prowise.io" } } as User : null);
    }

    /**
     * Log in.
     *
     * @param redirect login via redirect. If false it will determine it itself.
     * @returns A promise.
     */
    public async login(redirect = false): Promise<void> {
        this.setSignInStatus(SignInStatus.SINGING_IN);
        const urlParams = new URLSearchParams(window.location.search);
        const loginHint = urlParams.get("login_hint");
        let triggerRedirect = redirect;
        if (!triggerRedirect) {
            try {
                const user = await this.userManager.signinSilent({
                    login_hint: loginHint ?? undefined,
                });
                if (user) {
                    this.setSignInStatus(SignInStatus.SIGN_IN_FINiSHED);
                    this.userActions.mutate(user);
                    return;
                }
            } catch (error) {
                console.error(error);
                if ((error as Error).message.includes("authentication")) {
                    triggerRedirect = true;
                }
            }
        }
        if (triggerRedirect) {
            // Use this in case there was an issue with the silent login.
            await this.userManager.signinRedirect({
                login_hint: loginHint ?? undefined,
            });
        }
        this.setSignInStatus(SignInStatus.SIGN_IN_FINiSHED);
    }

    /**
     * Log out.
     *
     * @returns A promise.
     */
    public async logout(): Promise<void> {
        const idToken = (await this.userManager.getUser())?.id_token;
        return this.userManager.signoutRedirect({ id_token_hint: idToken });
    }

    /**
     * Try to process if the user was logged in.
     *
     * @returns True when logged in.
     */
    private tryProcessLogin(): boolean {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code") || urlParams.get("error");
        if (code !== null) {
            this.work.push(this.userManager.signinCallback().then(() => {
                // No window reload.
                window.history.replaceState({}, "", `${window.location.origin}${window.location.pathname}`);
            }));
            return true;
        }
        return false;
    }

    /**
     * Het the apps from the user.
     *
     * @returns A list of apps and dividers.
     */
    private getApps(): DividedAppsList {
        const user = this.user();
        if (!user) {
            return [];
        }
        const apps = user.profile?.apps as Record<string, App | Divider> | undefined;
        if (!apps) {
            return [];
        }
        return Object.values(apps);
    }

    /**
     * Entry is divider?
     *
     * @param entry App or Divider
     * @returns Divider type guard.
     */
    private isDivider(entry: App | Divider): entry is Divider {
        return (entry.id === "divider");
    }

    /**
     * Get all the apps structured for presentation. The apps are categorized by their respective dividers.
     *
     * @returns An array of apps with category.
     */
    public getAppsCategorized(): Array<GridItem<{ clientId: string; url: string }>> {
        let category = t("overview.p.public_apps");

        const apps = this.getApps().map<GridItem | undefined>((app, idx) => {
            if (this.isDivider(app)) {
                category = app.label;
                return undefined;
            }
            // Note: we may want to include app.image_mime
            return {
                id: `app_${idx}`,
                name: app.name,
                image: app.image_url,
                category,
                meta: {
                    clientId: app.id,
                    url: app.url,
                },
            };
        });

        return apps.filter((app) => !!app);
    }
}

let OIDC: OAuthService = undefined as unknown as OAuthService;

/**
 * Get authentication helper. (forced auto login.)
 *
 * @returns OAuthService
 */
export function useAuth(): OAuthService {
    if (!OIDC) {
        OIDC = new OAuthService();
    }
    return OIDC;
}
