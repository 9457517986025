import { Button, ButtonGroup, useTheme } from "@suid/material";
import { type Component, createSignal } from "solid-js";
import { type ToggleButtonsProps } from ".";

export const ToggleButtons: Component<ToggleButtonsProps> = (props) => {
    const theme = useTheme();
    // eslint-disable-next-line solid/reactivity -- default is only used initially, ignore reactive state
    const [value, setValue] = createSignal<string | undefined>(props.default ?? props.value ?? "");

    const handleChange = (event: MouseEvent): void => {
        if (!event.target) return;
        setValue((event.target as HTMLButtonElement).value);
    };

    return (
        <ButtonGroup data-testid="ToggleButtons" variant="outlined">
            {props.items && (
                Array.isArray(props.items)
                    ? props.items.map((label: string, index: number) => (
                        <Button
                            value={String(index)}
                            variant={value() === String(index) ? "contained" : undefined}
                            onClick={handleChange}
                            sx={theme.mixins.button}
                        >
                            {label}
                        </Button>))
                    : Object.keys(props.items).map(
                        (index: string) => (
                            <Button
                                value={index}
                                variant={value() === index ? "contained" : undefined}
                                onClick={handleChange}
                                sx={theme.mixins.button}
                            >
                                {/* items is definitely a Record (object) */}
                                {(props.items as Record<string, string>)[index]}
                            </Button>))
            )}
        </ButtonGroup>
    );
};
