import styled from "@suid/system/styled";
import { type StyledProps } from "@suid/system/styledProps";
import { type GridProps } from ".";

/** StyledGridProps */
type StyledGridProps = Pick<GridProps, "desktop" | "mobile">;

export const StyledGrid = styled("div", {
    name: "grid",
    skipProps: ["desktop", "mobile", "sx"],
})<StyledGridProps>(
    (params) => {
        const props = params.props as GridProps;
        const { theme } = params;

        const styles: StyledProps = {
            display: "grid",
            overflowY: "scroll",
            scrollbarWidth: "none", /* Firefox and modern Webkit */
            "-ms-overflow-style": "none" as StyledProps, /* Internet Explorer 10+ */
            "&::-webkit-scrollbar": { display: "none" }, /* Safari and older Webkit */

            gridGap: theme.spacing(1) as StyledProps,
            justifyContent: "center", // space-evenly
            gridTemplateColumns: `repeat(${props.desktop}, 88px)`,

            // Mobile width
            "@media (max-width: 872px)": {
                gridTemplateColumns: `repeat(${props.mobile}, 88px)`,
            },
            // Tiny width
            "@media (max-width: 456px)": {
                gridTemplateColumns: `repeat(${props.mobile}, minmax(auto, 88px))`,
            },
        };

        return styles;
    },
);
