import { useTheme } from "@suid/material";
import { ParentComponent } from "solid-js";
import { type CollapseButtonProps } from ".";
import { Button } from "../Button";

export const CollapseButton: ParentComponent<CollapseButtonProps> = (props) => {
    const theme = useTheme();

    return (<Button
        data-testid="CollapseButton"
        {...props}
        fullWidth={props.fullWidth && !props.collapsed}
        sx={{
            ...theme.mixins.buttonOutlined,
            justifyContent: props.collapsed ? "inherit" : "flex-start",
            ...props.collapsed ? {
                borderRadius: 1,
                minWidth: theme.spacing(5),
                px: 1,
                ".MuiButton-startIcon": {
                    m: 0,
                    "*:nth-of-type(1)": {
                        fontSize: "18px",
                    },
                },
            } : props.sx,
        }}
    >
        {props.collapsed ? null : <span style={{ "text-overflow": "ellipsis", "white-space": "nowrap", overflow: "hidden" }}>{props.children}</span>}
    </Button>
    );
};
