import { type Config, Phetch } from "@prowise/phetch";
import { config } from "../config";

/**
 * Pop parameter value from the URL, silently modifying it.
 *
 * @param name The name of the parameter.
 * @param keepLast Whether to keep the last value onto the URL, defaults to true.
 * @returns The first value of the parameter.
 */
function popParam(name: string, keepLast = true): string {
    // Copy over the search params.
    const uri = new URL(window.location.href);
    const paramValues = uri.searchParams.getAll(name);
    if (!paramValues.length) return "";

    // Get the first value
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We verified its length
    const paramValue = (paramValues.length > 1 || !keepLast) ? paramValues.shift()! : paramValues[0];

    // Remove all references
    uri.searchParams.delete(name);
    paramValues.forEach((otherParamValue) => uri.searchParams.append(name, otherParamValue));

    // Update the url without reload
    window.history.replaceState(null, document.title, uri);
    return paramValue;
}

/**
 * Debug Request interceptor.
 *
 * @param request Phetch Request.
 * @returns Phetch Request, optionally modified.
 */
async function debugRequestInterceptor<T extends Config>(request: T): Promise<T> {
    request.url?.searchParams.set("mockStatus", popParam("mockStatus"));
    request.url?.searchParams.set("payload", popParam("payload"));
    return request;
}

export const useDevelopmentParams = (): void => {
    if (config.debug.mockResponse) {
        console.warn("Demo params for MSW active");
        Phetch.interceptors.request.add(debugRequestInterceptor);
    }
};

// NOTE: this file may be overwritten in a release build by the following method:
// export const useDevelopmentParams = (): () => string => () => "";
