if (!Array.prototype.at) {
    /**
     * The at polyfill
     *
     * @param index The element index; negative values start from the end of the Array.
     * @returns The resulting element.
     */
    // eslint-disable-next-line no-extend-native -- Polyfill for Browsers < Oct 2020
    Array.prototype.at = function (index: number): unknown {
        if (index >= 0) return this[index];
        return this[this.length + index];
    };
}
