import { FormControl, InputLabel, MenuItem, Select } from "@suid/material";
import { SelectChangeEvent } from "@suid/material/Select";
import { createSignal, type ParentComponent } from "solid-js";
import { type DropdownProps } from ".";

export const Dropdown: ParentComponent<DropdownProps> = (props) => {
    // eslint-disable-next-line solid/reactivity -- default is only used initially, ignore reactive state
    const [value, setValue] = createSignal<string | undefined>(props.default ?? props.value ?? "");

    const handleChange = (event: SelectChangeEvent): void => {
        const result = props.handleChange?.(String(event.target.value));
        setValue(result ?? event.target.value);
    };

    return (
        <FormControl data-testid="Dropdown" fullWidth>
            {props.label ? <InputLabel id={props.label}>{props.label}</InputLabel> : null}
            <Select
                labelId={props.label}
                value={props.value ?? value()}
                label={props.label}
                onChange={handleChange}
            >
                {props.items && (
                    Array.isArray(props.items)
                        ? props.items.map((label: string, index: number) => <MenuItem value={index}>{label}</MenuItem>)
                        : Object.keys(props.items).map(
                            (index: string) => <MenuItem value={index}>
                                {/* items is definitely a Record (object) */}
                                {(props.items as Record<string, string>)[index]}
                            </MenuItem>)
                )}
                {props.children}
            </Select>
        </FormControl>
    );
};
