if (typeof HTMLFormElement.prototype.requestSubmit !== "function") {
    /**
     * requestSubmit polyfill
     *
     * @param submitter The string or regular expression to match.
     * @throws TypeError if submitter is not submit button or submitter parent form is not us.
     */
    HTMLFormElement.prototype.requestSubmit = function (submitter?: HTMLElement) {
        if (submitter) {
            if (!(submitter instanceof HTMLInputElement)) throw new TypeError("parameter 1 is not of type 'HTMLInputElement'");
            if (submitter.type !== "submit") throw new TypeError("The specified element is not a submit button");
            if (submitter.form !== this) throw new DOMException("The specified element is not owned by this form element", "NotFoundError");

            submitter.click();
        } else {
            const submitButton = document.createElement("input");
            submitButton.type = "submit";
            submitButton.hidden = true;
            this.appendChild(submitButton);
            submitButton.click();
            this.removeChild(submitButton);
        }
    };
}
