/**
 * Split flags from flag name
 *
 * @param flagName The name of the variable we want to distill the flags from
 * @returns object containing flags set to true.
 */
export function splitConfigFlags<T extends Record<string, true> = Record<string, true>>(flagName: string): T {
    const dataSetValue = document.getElementById("sso-root")?.dataset[flagName.toLowerCase()];
    const viteValue = import.meta.env[`VITE_${flagName.toUpperCase()}`] as string | undefined;

    const value = dataSetValue?.match(/^<!--(?:(?!-->).)+-->$/) ? viteValue : dataSetValue;

    return value?.split(/\s*,\s*/).reduce<T>((flags, flag) => {
        if (flag) {
            (flags as Record<string, true>)[flag] = true;
        }
        return flags;
    }, {} as T) ?? {} as T;
}
