import { useTheme } from "@suid/material";
import { createEffect, createSignal, onCleanup, splitProps, type ParentComponent } from "solid-js";
import { type ThrottledButtonProps } from "./index";
import { Button } from "../Button";

export const ThrottledButton: ParentComponent<ThrottledButtonProps> = (props) => {
    const theme = useTheme();
    const [local, others] = splitProps(props, ["disableTime"]);
    const [disabled, setDisabled] = createSignal(true);

    let timer: number | undefined;
    createEffect(() => {
        setDisabled(true);
        timer = setTimeout(() => {
            timer = undefined;
            setDisabled(false);
        }, (local.disableTime ?? 1) * 1000);
    });

    onCleanup(() => {
        if (timer) clearTimeout(timer);
    });

    return (
        <Button
            data-testid="ThrottledButton"
            disabled={disabled()}
            {...others}
            sx={{ ...theme.mixins.button, ...props.sx }}
        >
            {props.children}
        </Button>
    );
};
