import { SchoolRounded, QrCodeScannerRounded, PhotoSizeSelectActualOutlined } from "@suid/icons-material";

import { Link, Paper, Stack, TextField, Typography, useTheme } from "@suid/material";
import { Component, createSignal } from "solid-js";
import apple_svg from "../assets/providers/apple.svg";
import google_svg from "../assets/providers/google.svg";
import klascement_svg from "../assets/providers/klascement.svg";
import microsoft_svg from "../assets/providers/microsoft.svg";
import { Button } from "../components/Button";
import { CollapseButton } from "../components/CollapseButton";
import { CollapsePanel } from "../components/CollapsePanel";
import { Dropdown } from "../components/Dropdown";
import { LocaleMenu } from "../components/LocaleMenu";
import { ToggleButtons } from "../components/ToggleButtons";
import { BCP47 } from "../utilities/defaultLocales";
import { MarkdownLink } from "../utilities/Parser/MarkdownLink";

const items = {
    NL: "Netherlands",
    BE: "Belgium",
    DA: "Denmark",
    DE: "Germany",
};
export const Test: Component<{ bool?: boolean }> = () => {
    const theme = useTheme();
    const [dropdownValue, setDropdownValue] = createSignal<string | undefined>();
    const onChange = (value: string): string | undefined => {
        if (value === "DE") {
            setDropdownValue("BE");
            return "BE";
        }

        setDropdownValue(value);
        return undefined;
    };

    const [checked, setChecked] = createSignal(false);
    const handleChange = (): void => {
        setChecked((prev) => !prev);
    };

    return (
        <div>
            <h1>Component demo</h1>
            <ul>
                <li>
                    <h4>MarkdownLink</h4>
                    <ul>
                        <li><MarkdownLink text="hello" /></li>
                        <li><MarkdownLink text="hello [Mark Down](gopher://link/) link" /></li>
                        <li><MarkdownLink text={'hello [Mark Down](gopher://link/ "tooltip")'} /></li>
                    </ul>
                </li>
                <li>
                    <h4>primary button</h4>
                    <Button variant="contained" color="primary">Login</Button>
                </li>
                <li>
                    <h4>secondary button</h4>
                    <Button sx={{ mr: 1 }} variant="contained" color="secondary">Login</Button>
                    <Button sx={{ mr: 1 }} variant="contained" color="info">Login</Button>
                    <Button sx={{ mr: 1 }} variant="contained" color="inherit">Login</Button>
                </li>
                <li>
                    <h4>outlined button</h4>
                    <Button variant="outlined" color="primary">Login</Button>
                </li>
                <li>
                    <h4>Button group</h4>
                    <ToggleButtons items={{ item1: "Item 1", item2: "Item 2", item3: "Item 3" }} default="item2" />
                </li>
                <li>
                    <h4>text links</h4>
                    Go to <Link color="primary" href="https://prowise.nl">Prowise</Link>
                </li>
                <li>
                    <h4>text (font/padding/margins)</h4>
                    <Typography variant="body1" color="text.primary">
                        Primary
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        ⌘X
                    </Typography>
                </li>
                <li>
                    <h4>boxes/layers</h4>
                    <Paper
                        elevation={3}
                        sx={{ maxWidth: 400, p: 4, backgroundColor: theme.palette.secondary.light, boxSizing: "border-box" }}
                    >
                        use {"<AppFrame>for centering</AppFrame>"}
                    </Paper>

                </li>
                <li>
                    <h4>input (text/password)</h4>
                    <TextField fullWidth label="E-mail" variant="filled" color="secondary" />
                    <TextField label="test" variant="outlined" color="secondary" />
                    <TextField label="Password" type="password" variant="filled" color="secondary" />
                </li>
                <li>
                    <h4>dropdown</h4>
                    <Stack spacing={2}>
                        <Dropdown label="Select country" items={items} handleChange={onChange} value={dropdownValue()} />
                        <Dropdown items={items} handleChange={onChange} value={dropdownValue()} />
                    </Stack>
                </li>
                <li>
                    <h4>language dropdown</h4>
                    Select language menu: <LocaleMenu name="Language" default={BCP47.EN_GB} />
                </li>
                <li>
                    <h4>provider panel (expandable)</h4>
                    <CollapsePanel
                        expanded={checked()}
                        onChange={handleChange}
                        topChildren={(expanded) => (<>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<img width="20px" height="auto" src={google_svg} />}>Sign in with Google</CollapseButton>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<img width="20px" height="auto" src={microsoft_svg} />}>Sign in with Microsoft</CollapseButton>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<img width="auto" height="20px" src={apple_svg} />}>Sign in with Apple</CollapseButton>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<img width="20px" height="auto" src={klascement_svg} />}>Sign in with KlasCement</CollapseButton>
                        </>)}
                        bottomChildren={(expanded) => (<>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<SchoolRounded color="primary" />}>Sign in with school account</CollapseButton>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<PhotoSizeSelectActualOutlined color="primary" />}>Sign in with pictures</CollapseButton>
                            <CollapseButton collapsed={expanded} variant="outlined" startIcon={<QrCodeScannerRounded color="primary" />}>Sign in from other device</CollapseButton>
                        </>)}
                    />
                </li>
            </ul>
        </div>
    );
};
