import { ExpandMore, NavigateNext } from "@suid/icons-material";
import { ListItemIcon, ListItemText, MenuItem, useTheme } from "@suid/material";
import { ParentComponent, createSignal } from "solid-js";
import { type TreeItemProps } from ".";
import { Flag } from "../Flag";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TreeItem: ParentComponent<TreeItemProps<any>> = (props) => {
    const theme = useTheme();

    // eslint-disable-next-line solid/reactivity -- Only set initially.
    const [expanded, setExpanded] = createSignal(props.expanded);
    return (
        <>
            <MenuItem
                data-testid={props.id}
                onClick={() => { props.onClick?.(props); }}
                selected={props.selected}
                aria-expanded={expanded()}
                sx={{ pl: 2 + (props.level ?? 0 * 2), ...theme.mixins.menuItem }}
                onKeyDown={(event) => {
                    if ((expanded() === false && event.key === "ArrowRight") ||
                        (expanded() === true && event.key === "ArrowLeft")) {
                        setExpanded((old) => !old);
                        props.action?.updatePosition();
                    }
                }}
            >
                {props.showIcon ? <ListItemIcon>
                    <Flag locale={props.id} />
                </ListItemIcon> : null}
                <ListItemText>
                    {props.label}
                </ListItemText>
                <ListItemIcon onClick={(e) => {
                    setExpanded((old) => !old);
                    props.action?.updatePosition();
                    e.stopPropagation();
                }}>
                    {expanded() === true ? <ExpandMore /> : null}
                    {expanded() === false ? <NavigateNext /> : null}
                </ListItemIcon>
            </MenuItem>
            {expanded() ? props.children : null}
        </>
    );
};
