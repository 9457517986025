import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@suid/icons-material";
import { Divider, Stack } from "@suid/material";
import { Component } from "solid-js";
import { type CollapsePanelProps } from ".";

/**
 * Dynamic panel that renders items in a callback with the "expanded" state.
 * Useful for displaying inline icon buttons and full width buttons.
 *
 * @param props The panel's properties.
 * @returns Panel instance.
 */
export const CollapsePanel: Component<CollapsePanelProps> = (props) => {
    const handleChange = (): void => {
        props.onChange?.(!props.expanded);
    };

    return (
        <div data-testid="CollapsePanel">
            <Stack
                justifyContent="center"
                spacing={2}
                direction={props.expanded ? "column" : "row" }>
                {props.topChildren?.(!props.expanded)}
            </Stack>
            <Divider sx={{ cursor: "pointer" }} onClick={handleChange} color="primary">{props.expanded ? <KeyboardArrowUpRounded color="primary" /> : <KeyboardArrowDownRounded color="primary" />}</Divider>
            <Stack
                justifyContent="center"
                spacing={2}
                direction={props.expanded ? "row" : "column"}>
                {props.bottomChildren?.(!!props.expanded)}
            </Stack>
        </div>
    );
};
