import { Button } from "@suid/material";
import styled from "@suid/system/styled";
import { type StyledProps } from "@suid/system/styledProps";
import { type ButtonProps } from ".";

// Note: this button cannot be used in a button group since the corresponding classnames are not applied.
export const ButtonStyled = styled(Button)<ButtonProps>(
    (params) => {
        const props = params.props as ButtonProps;
        const { theme } = params;
        const inverted = props.inverted || false;

        const styles: StyledProps = {};
        if (inverted) {
            styles.backgroundColor = theme.palette.common.white;
        }

        return styles;
    },
);
