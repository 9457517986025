import { CircularProgress, Stack, Typography } from "@suid/material";
import { t } from "i18next";
import { createSignal, onMount, ParentComponent } from "solid-js";
import { type LoaderProps } from ".";
import { LoginDialog } from "../LoginDialog";

export const Loader: ParentComponent<LoaderProps> = (props) => {
    // Set show content to false if we have a delay.
    const [showContent, setShowContent] = createSignal(!props.delay);

    onMount(() => {
        if (props.delay) {
            setTimeout(() => {
                setShowContent(true);
            }, props.delay === true ? 1500 : props.delay);
        }
    });

    // The actual content
    const content = showContent() && (<Stack direction="column" alignItems="center" gap={2}>
        <CircularProgress />
        {props.children ?? props.noFallbackTitle ? undefined : <Typography>{t("general.title.loading")}</Typography>}
    </Stack>);

    // Wrap content in a dialog, if requested
    return <>{props.dialog ? <LoginDialog id="loading" errors={[]} >{content}</LoginDialog> : content}</>;
};
