import { Theme } from "@suid/material";
import { SxProps } from "@suid/system";
import i18next from "i18next";
import { JSXElement } from "solid-js";

const surrogatePairs = /^[\uD800-\uDBFF][\uDC00-\uDFFF]/;
const encoder = new TextEncoder();

/**
 * Converts a given name to one or two initials.
 * NOTE: this function is a very naive (germanic naming) approach.
 * And doesn't take internationalization into account.
 * For a better overview, see https://developer.apple.com/documentation/foundation/personnamecomponentsformatter
 * Also, for surrogate pairs, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charAt.
 *
 * @param fullName The full name of the user/participant.
 * @returns One or two initials in uppercase derived from the first and last name.
 */
export function getInitials(fullName: string): string {
    const names = fullName.trim().split(/\s+/);

    // We only want the first and last names: no middle names or prefixes
    if (names.length > 2) {
        names[1] = names[names.length - 1];
        names.length = 2;
    }

    return names.map((name) => {
        // Nothing to do if we have no text
        if (!name.length) return "";

        // Surrogate pairs cannot be split
        if (surrogatePairs.exec(name)) {
            return `${name[0]}${name[1]}`.toLocaleUpperCase(i18next.language);
        }
        // Regular character
        return name[0].toLocaleUpperCase(i18next.language);
    }).join("");
}

/** Synchronous crc32 function. */
const crc32 = (() => {
    const table = new Uint32Array(256);

    // Pre-generate crc32 polynomial lookup table
    // http://wiki.osdev.org/CRC32#Building_the_Lookup_Table
    for (let i = 256; i--;) {
        let tmp = i;

        for (let k = 8; k--;) {
            // eslint-disable-next-line no-bitwise
            tmp = tmp & 1 ? 3988292384 ^ tmp >>> 1 : tmp >>> 1;
        }

        table[i] = tmp;
    }

    return (data: Uint8Array): number => {
        let crc = -1; // Begin with all bits set ( 0xffffffff )

        for (let i = 0, l = data.length; i < l; i++) {
            // eslint-disable-next-line no-bitwise
            crc = crc >>> 8 ^ table[crc & 255 ^ (data[i])];
        }

        // eslint-disable-next-line no-bitwise
        return (crc ^ -1) >>> 0; // Apply binary NOT
    };
})();

/**
 * Converts a given string to color.
 *
 * @param string The text to generate a color for.
 * @returns A unique color string generated (hash-like) from the given text.
 */
export function stringToColor(string: string): string {
    return `#${
        crc32(encoder.encode(string))
            .toString(16)
            .padStart(8, "0")
            .substring(2)
    }`;
}

/** Avatar properties. */
type AvatarProps = {
    /** Style. */
    sx?: SxProps<Theme>;
    /** Children. */
    children: JSXElement;
};

/**
 * Generate initials and a corresponding color for a given name.
 *
 * @param fullName The full name of the user/participant.
 * @param generateColor Whether to generate a specific color for this name.
 * @returns One or two initials in uppercase derived from the first and last name.
 */
export function stringAvatar(fullName: string, generateColor = true): AvatarProps {
    const props: AvatarProps = {
        children: getInitials(fullName),
    };

    if (generateColor && fullName) {
        props.sx = {
            backgroundColor: fullName ? stringToColor(fullName) : undefined,
        };
    }

    return props;
}
